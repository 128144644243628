<template lang="pug">
	.widget
		el-card.box-card
			el-row(slot="header")
				el-col.text-left(:span="24")
					.d-flex.justify-content-between
						h3
							b Niche - {{this.type}} {{type === 'Create' ? 'new' : '' }} entity
			el-form(:form="form" )
				el-row(:gutter="12")
					el-col(:xs="24", :sm="12" :md="8")
						el-form-item(label="Name" v-model="form.name" :error="errors.name.s ? errors.name.m : ''" v-bind:class="{'is-invalid': errors.name.s}")
							el-input(v-model="form.name" placeholder="Name")
					el-col(:span="24")
						el-form-item(label="Comments" v-model="form.comment" :error="errors.comment.s ? errors.comment.m : ''" v-bind:class="{'is-invalid': errors.comment.s}")
							el-input(type="textarea" :rows="4" v-model="form.comment" placeholder="Name" show-word-limit="" maxlength="4096")
					el-col(:span="24")
						el-form-item(label="Funnels List" label-position="top" label-width="auto" :error="errors.list.s ? errors.list.m : ''" v-bind:class="{'is-invalid': errors.list.s}")
							br
						el-transfer.text-left.lead-box( filterable="" :data="options.list" v-model="form.list" :titles="['Available','Target']")

				el-divider
				el-row(:gutter="12" justify="between")
					el-button(@click="submit(true)" type="primary") Save
					el-button(type="danger" @click="$router.push({name: 'iframe-list'})") Cancel


</template>

<style lang="scss">

</style>

<script lang="js">
import Vue from "vue";
import validator from "validator";

const $form = {
	name: '',
	comment: '',
	list: []
};

const $error = {
	name: {m: '', s: false},
	comment: {m: '', s: false},
	list: {m: '', s: false}
};

export default {
	name: 'niche-form',
	data() {
		return {
			type: 'Create',
			form: JSON.parse(JSON.stringify($form)),
			originalName: '',
			errors: JSON.parse(JSON.stringify($error)),
			options: {list: []},
		}
	},
	async beforeRouteEnter(to, from, next) {
		if (to.name !== 'iframe-add-niche' && to.name !== 'iframe-edit-niche') return next();
		if (to.name === 'iframe-add-niche') {
			const options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: '95e5744a-9964-42d4-ae26-c3e591ecb7e0'});
			next(vm => {
				vm.options = {};
				vm.options.list = options.funnel_families.map(e => Object.assign({}, {
					key: e.v,
					label: `${e.t} (${e.rn || 'NO_NAME'})`
				}));
				vm.form = JSON.parse(JSON.stringify($form));
				vm.errors = JSON.parse(JSON.stringify($error));
				vm.type = 'Create';
			});
		} else if (to.name === 'iframe-edit-niche') {
			const options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: '95e5744a-9964-42d4-ae26-c3e591ecb7e0'});

			const result = await Vue.apix.sendHttpRequest('GET', 'niche/' + to.params.id);

			next(vm => {
				vm.options = {};
				vm.options.list = options.funnel_families.map(e => Object.assign({}, {
					key: e.v,
					label: `${e.t} (${e.rn || 'NO_NAME'})`
				}));
				vm.form = JSON.parse(JSON.stringify($form));
				vm.form.name = result.name;
				vm.form.comment = result.comment;
				vm.form.list = result.funnel_family.map(e => e.id);
				vm.originalName = result.name;
				vm.errors = JSON.parse(JSON.stringify($error));
				vm.type = 'Edit';
			});
		}
	},
	methods: {
		preparePayload() {
			const body = {};
			body.name = `${this.form.name}`;
			body.comment = `${this.form.comment}`;
			body.list = [...this.form.list];
			return body;
		},
		submit() {
			const payload = this.preparePayload();
			if (!this.validate()) return this.$notify.warning({
				title: 'Validation error',
				message: 'Please, fix the validation errors in this form to proceed. You can see the error text in red font'
			});
			if (this.type === 'Create') {
				let loader = this.$loading.show();
				this.$apix.sendHttpRequest('POST', 'niche/', payload)
					.then(res => {
						if (res.id) this.$router.push({name: 'iframe-list'})
						loader.hide()
					})
					.catch(err => {
						this.$notify.error('There was an issue. We are fixing the bug');
						console.error(err);
						loader.hide();
					});
			} else if (this.type === 'Edit') {
				let loader = this.$loading.show();
				this.$apix.sendHttpRequest('PUT', 'niche/' + this.$route.params.id, payload)
					.then(res => {
						if (res.id) this.$router.push({name: 'iframe-list'})
						loader.hide()
					})
					.catch(err => {
						this.$notify.error('There was an issue. We are fixing the bug');
						console.error(err);
					});
			}
		},
		async validate() {
			this.errors = JSON.parse(JSON.stringify($error));

			let error = false;

			if (this.form.name === '') {
				this.errors.name = { s: true, m: 'name cannot be empty' };
				error = true;
			} else if (!validator.isAscii(this.form.name)) {
				this.errors.name = { s: true, m: 'name malformed' };
				error = true;
			} else {
				const check = await this.$apix.sendHttpRequest('GET', 'niche/exists', {name: this.form.name});
				if (this.type === 'Create' && check.exists) {
					this.errors.name = { s: true, m: 'name already exists' };
					error = true;
				} else if (this.type === 'Edit' && this.form.name !== this.originalName && check.exists) {
					this.errors.name = { s: true, m: 'name already exists' };
					error = true;
				}
			}


			if(this.form.list.length === 0) {
				this.errors.list = { s: true, m: 'funnel list cannot be empty' };
				error = true;
			}

			return !error;
		}
	}
}
</script>